<template>
  <div class="controls">
    <h2>Mal’ mir was vor!</h2>
    <p>
      Wahrscheinlich sollte hier noch ein bisschen Text stehen, was das soll.
      Aber malt doch einfach drauf los und gönnt euch. Man muss ja auch nicht
      immer alles erklären. Denkt euch einfach irgend etwas mit Synästhesie von
      Musik und Farben, oder so.
    </p>
    <br />
    <button @click="toolBoxIsOpen = !toolBoxIsOpen">
      Werkzeuge<svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="#333448"
        stroke-width="1"
        style="margin-left: 10px; transform: rotate(90deg)"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
        />
      </svg>
    </button>
    <div v-if="toolBoxIsOpen">
      <div class="sliderBar">
        <Slider
          label="Size"
          min="16"
          max="128"
          value="store.state.size"
          :currentValue="store.state.size"
        />
        <Slider
          label="Gravity"
          min="-2"
          max="2"
          value="store.state.gravity"
          :currentValue="store.state.gravity"
        />
        <Slider
          label="Speed"
          min="1"
          max="10"
          value="store.state.acc"
          :currentValue="store.state.acc"
        />
        <Slider
          label="Spread"
          min="0"
          :max="windowWidth"
          :currentValue="store.state.dim"
        />

        <div class="colorSelection">
          <select
            name="colors"
            id="colors"
            v-model="selectedScheme"
            @change="selectColor"
          >
            <option
              v-for="(option, index) in store.state.colors"
              :key="option"
              :value="index"
            >
              {{ colorLabels[index] }}
            </option>
          </select>
          <label for="colors">color scheme</label>
        </div>
      </div>
      <div class="download-clear">
        <button class="redHover" @click="clearCanvas">
          clear
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="#333448"
            stroke-width="1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
        <button @click="saveCanvas">
          Download
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="#333448"
            stroke-width="1"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import Slider from "@/components/Slider";
import { ref, computed } from "vue";
import { useStore } from "vuex";

const toolBoxIsOpen = ref(false);
const store = useStore();
const selectedScheme = ref(0);
const windowWidth = window.innerWidth / 2;

const colorLabels = computed(() => ["Pale", "Blue", "Orange", "Pink"]);

const selectColor = () => {
  store.commit("selectColor", selectedScheme);
};

const clearCanvas = () => {
  store.commit("clearCanvas");
};

const saveCanvas = () => {
  store.commit("saveCanvas");
};
</script>

<style lang="scss">
.controls {
  margin-top: 150px;
  margin-bottom: 20px;

  @include break(900px) {
    margin-top: 100px;
  }

  @include break(500px) {
    margin-top: 75px;
  }

  .download-clear {
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
    gap: 20px;
  }
}

.sliderBar {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  justify-content: space-between;
  margin: 40px 0 20px 0;

  @include break(900px) {
    grid-template-columns: repeat(2, calc(50% - 20px));
  }

  @include break(500px) {
    grid-template-columns: 1fr;
  }
}

/********** */
/* BUTTONS */
/********** */

button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1.4rem;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.25s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: 1px solid $color2;
  cursor: pointer;
  min-width: 120px;
  max-width: fit-content;
  height: 50px;

  @include break(500px) {
    min-width: 100px;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color1;
    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: $color3;
    transition: all 0.25s;
    border-radius: 10rem;
    z-index: -1;
  }

  &:hover {
    svg {
      stroke: #fff;
    }
    &:before {
      width: 100%;
    }
  }

  svg {
    height: 24px;
    height: 24px;
  }
}

.greenHover {
  &:before {
    background-color: $color4;
  }

  &:hover {
    svg {
      stroke: #fff;
    }
  }
}

.redHover {
  &:before {
    background-color: $color6;
  }

  &:hover {
    color: white;
    svg {
      stroke: #fff;
    }
  }
}

.yellowHover {
  &:before {
    background-color: $color5;
  }

  &:hover {
    color: white;
    svg {
      stroke: #fff;
    }
  }
}

/********** */
/* SELECT DROPDOWN */
/********** */

.colorSelection {
  display: flex;
  align-items: center;
  flex-direction: column;

  select {
    width: 100%;
    background: $color3;
    padding: 4px 16px 4px 8px;
    margin: 4px 0;
    border-radius: 42px;
  }

  label {
    font-size: 12px;
  }
}
</style>