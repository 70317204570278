<template>
  <div class="label">
    <input
      type="range"
      name="size"
      :min="min"
      :max="max"
      :value="currentValue"
      @change="changeValues($event)"
    />
    <label for="size">{{ label }}: {{ currentValue }}</label>
  </div>
</template>

<script>
export default {
  props: ["label", "min", "max", "currentValue"],

  methods: {
    changeValues(value) {
      this.$store.commit("changeValues", [this.label, value.target.value]);
    },
  },
};
</script>

<style lang="scss" scoped>
/********** */
/* RANGE SLIDERS */
/********** */

input[type="range"] {
  height: 16px;
  -webkit-appearance: none;
  margin: 10px 0;
  margin-right: 8px;
  width: 100%;
  background: none;

  @include break(900px) {
    margin: 4px 0;
  }
}

input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 20px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px $color3;
  background: $color3;
  border-radius: 50px;
  border: 0px solid $color3;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px $color3;
  border: 2px solid $color3;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background: $color5;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: $color3;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 20px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px $color3;
  background: $color3;
  border-radius: 50px;
  border: 0px solid $color3;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px $color3;
  border: 2px solid $color3;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background: $color5;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 20px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: $color3;
  border: 0px solid $color3;
  border-radius: 100px;
  box-shadow: 0px 0px 0px $color3;
}
input[type="range"]::-ms-fill-upper {
  background: $color3;
  border: 0px solid $color3;
  border-radius: 100px;
  box-shadow: 0px 0px 0px $color3;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px $color3;
  border: 2px solid $color3;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background: $color5;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: $color3;
}
input[type="range"]:focus::-ms-fill-upper {
  background: $color3;
}

.label {
  display: flex;
  flex-direction: column;

  label {
    min-width: 110px;
    white-space: nowrap;
    font-size: 12px;
    text-align: center;
  }
}
</style>