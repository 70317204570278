import { createStore } from 'vuex'

export default createStore({
  state: {
    clearCanvas: false,
    saveCanvas: false,
    size: 32,
    acc: 5,
    dim: 10,
    gravity: 0,
    selectedColorScheme: 0,
    colors: [
      [
        //mixed
        [254, 197, 187, 255],
        [252, 213, 206, 255],
        [250, 225, 221, 255],
        [248, 237, 235, 255],
        [232, 232, 228, 255],
        [216, 226, 220, 255],
        [236, 228, 219, 255],
        [255, 229, 217, 255],
        [255, 215, 186, 255],
        [254, 200, 154, 255],
      ],
      [
        // blues
        [1, 42, 74, 255],
        [1, 58, 99, 255],
        [1, 73, 124, 255],
        [1, 79, 134, 255],
        [42, 111, 151, 255],
        [44, 125, 160, 255],
        [70, 143, 175, 255],
        [97, 165, 194, 255],
        [137, 194, 217, 255],
        [169, 214, 229, 255],
      ],
      [
        // yellows
        [255, 123, 0, 255],
        [255, 136, 0, 255],
        [255, 149, 0, 255],
        [255, 162, 0, 255],
        [255, 170, 0, 255],
        [255, 183, 0, 255],
        [255, 195, 0, 255],
        [255, 208, 0, 255],
        [255, 221, 0, 255],
        [255, 234, 0, 255],
      ],
      [
        // pinks
        [255, 203, 242, 255],
        [243, 196, 251, 255],
        [236, 188, 253, 255],
        [229, 179, 254, 255],
        [226, 175, 255, 255],
        [222, 170, 255, 255],
        [216, 187, 255, 255],
        [208, 209, 255, 255],
        [200, 231, 255, 255],
        [192, 253, 255, 255],
      ],
    ],
    audio: [
      {
        "name": "Hallo Welt!",
        "url": './assets/mp3/01.mp3',
        "length": "01:15"
      },
      {
        "name": "Kaskade",
        "url": './assets/mp3/02.mp3',
        "length": "02:05"
      },
      {
        "name": "Ein Lied für die Nachbarn",
        "url": './assets/mp3/03.mp3',
        "length": '02:12' 
      },
      {
        "name": "Vorher besser üben | Sega Prelude",
        "url": './assets/mp3/04.mp3',
        "length": '01:41' 
      },
      {
        "name": "Sega",
        "url": './assets/mp3/05.mp3',
        "length": '06:21' 
      },
      {
        "name": "Schunkeln",
        "url": './assets/mp3/06.mp3',
        "length": '02:05' 
      },
      {
        "name": "Der Traum ist an!",
        "url": './assets/mp3/07.mp3',
        "length": '03:36' 
      },
      {
        "name": "Sehr früh am Morgen",
        "url": './assets/mp3/08.mp3',
        "length": '01:42' 
      },
      {
        "name": "Schmelzender Schnee",
        "url": './assets/mp3/09.mp3',
        "length": '01:41' 
      },
      {
        "name": "Zeitlupen",
        "url": './assets/mp3/10.mp3',
        "length": '06:57' 
      },
      {
        "name": "Wie ging Gnossiennes No. 1 nochmal?",
        "url": './assets/mp3/11.mp3',
        "length": '03:44' 
      },
    ],
    //trackDurations: [],
  },
  mutations: {
    changeValues(state, payload) {
      switch(payload[0]) {
      case 'Size':
        state.size = payload[1]
        break;
      case 'Speed':
        state.acc = payload[1]
        break;
      case 'Spread':
        state.dim = payload[1]
        break;
      case 'Gravity':
        state.gravity = payload[1]
        break;
      }
    },
    clearCanvas(state) {
      state.clearCanvas = !state.clearCanvas;
    },
    saveCanvas(state) {
      state.saveCanvas = !state.saveCanvas;
    },
    selectColor(state, selectedScheme) {
      state.selectedColorScheme = selectedScheme
    },
/*     setTracklengths(state, length) {
      state.trackDurations.push(length)
    } */
  },
  actions: {
/*     async calculateDurations({ commit, state }, payload) {
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const file = await fetch(payload.url, {
        headers: {
          "Content-Type": "arraybuffer",
        },
      });
      const response = await file.arrayBuffer();
      const decoded = await audioContext.decodeAudioData(response);
      let duration = await new String(decoded.duration);

      duration = new Date(duration * 1000).toISOString().substr(14, 5);

      commit('setTracklengths', duration)
    } */
  },
  modules: {
  },
  getters: {}
})
