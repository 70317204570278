<template>
  <div class="playlist">
    <h3>The Past inside the Present</h3>
    <ul>
      <li
        @click="$emit('indexFromPlaylist', index)"
        v-for="(track, index) in tracks"
        :key="track"
        :class="{ currentTrack: currentlyPlaying == index }"
      >
        <span style="padding-right: 20px">{{ track.name }}</span>
        <span>{{ track.length }}</span>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, computed } from "@vue/runtime-core";
import { useStore } from "vuex";

const store = useStore();
const tracks = store.state.audio;
const props = defineProps(["currentlyPlaying"]);
//const durations = ref([]);

/* onBeforeMount(() => {
   tracks.forEach((track) =>
    store.dispatch("calculateDurations", { url: track.url })
  ); 
}); */

//onMounted(() => (durations.value = store.state.trackDurations));
</script>

<style lang="scss" scoped>
.playlist {
  background: $color1;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 42px rgba(51, 52, 72, 0.08);
  width: 650px;
  max-width: 100%;
  margin: 20px auto;
}

h3 {
  margin-bottom: 20px;
  color: $color2;
}

ul {
  display: flex;
  flex-direction: column;
  li {
    list-style: none;
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #a0a0a0;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: $color7;
    }
  }
}
.currentTrack {
  color: $color7;
}

span {
  font-family: "Gosha";
}
</style>