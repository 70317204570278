<template>
  <div>
    <!-- TO DO: add key, that changes, when window is resized to retrigger rendering -->
    <div ref="canvasContainer" id="p5CanvasContainer"></div>
  </div>
</template>

<script setup>
import p5 from "p5";
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

let circles = [];
let maxCircles = 256;

let offX, offY;
let store = useStore();

let canvasContainer = ref(null);

const sketch = (s) => {
  s.setup = () => {
    let canvas;
    if (window.innerWidth >= 1440) {
      canvas = s.createCanvas(1440 - 40, window.innerHeight * 0.8);
    } else {
      canvas = s.createCanvas(window.innerWidth - 40, window.innerHeight * 0.8);
    }
    canvas.parent(canvasContainer.value);
    s.background("#babab6");
  };
  s.draw = () => {
    let dim = store.state.dim;
    offX = s.random(-dim / 4, dim / 4);
    offY = s.random(-dim / 4, dim / 4);

    for (let i = circles.length - 1; i >= 0; i--) {
      circles[i].draw();
      circles[i].changeSize();
      circles[i].move();
      circles[i].fadeColor();

      if (circles[i].offScreen()) {
        circles.splice(i, 1);
      }
    }

    if (store.state.clearCanvas) {
      s.background("#babab6");
      store.commit("clearCanvas");
    }

    if (store.state.saveCanvas) {
      s.textSize(10);
      s.fill(50);
      s.text("luftlaeufer.de", s.width - 80, s.height - 20);
      s.save("Lukas Luftläufer.jpg");
      store.commit("saveCanvas");
    }
  };

  function mouseWithinCanvas() {
    return (
      s.mouseX < s.width && s.mouseX > 0 && s.mouseY > 0 && s.mouseY < s.height
    );
  }

  s.mouseDragged = () => {
    if (circles.length < maxCircles && mouseWithinCanvas()) {
      let c = new Circle(s.mouseX + offX, s.mouseY + offY);
      circles.push(c);
    }
  };

  class Circle {
    constructor(posX, posY) {
      this.x = posX;
      this.y = posY;
      this.noiseID_X = s.int(s.random(9999999));
      this.noiseID_Y = s.int(s.random(9999999));
      this.speed = s.random(0.01, 0.001);
      this.gravity = s.float(store.state.gravity);
      this.acc = s.random(1, store.state.acc);
      this.size = s.random(16, store.state.size);
      this.selectedColorScheme = [
        ...store.state.colors[store.state.selectedColorScheme],
      ];
      this.selectedColor = s.int(
        s.random(0, this.selectedColorScheme.length - 1)
      );
      this.fillColor = [...this.selectedColorScheme[this.selectedColor]];
    }

    move() {
      this.x += s.map(
        s.noise(s.frameCount * this.speed, this.noiseID_X),
        0,
        1,
        -this.acc,
        this.acc
      );
      this.y += s.map(
        s.noise(s.frameCount * this.speed, this.noiseID_Y),
        0,
        1,
        -this.acc,
        this.acc
      );

      this.y += this.gravity;
    }

    changeSize() {
      this.size -= this.acc * 0.05;
    }

    fadeColor() {
      this.fillColor[3] -= this.acc / 2;
      s.fill(
        this.fillColor[0],
        this.fillColor[1],
        this.fillColor[2],
        this.fillColor[3]
      );
    }

    draw() {
      //s.fill(this.fillColor[0], this.fillColor[1], this.fillColor[2])
      s.stroke(50, 50);
      s.circle(this.x, this.y, this.size, this.size);
    }

    offScreen() {
      return (
        this.x > s.width + this.size ||
        this.x < -this.size ||
        this.y > s.height + this.size ||
        this.y < -this.size ||
        this.size < 0 ||
        this.fillColor[3] <= 0
      );
    }
  }
};

onBeforeMount(() => {
  let app = new p5(sketch, "p5CanvasContainer");
});
</script>

<style lang="scss">
#p5CanvasContainer {
  border-radius: 20px;
  margin-bottom: 20px;

  canvas {
    cursor: pointer;
    border-radius: 20px;
    max-width: 100%;
    box-shadow: 0 0 42px rgba(51, 52, 72, 0.08);
  }
}
</style>