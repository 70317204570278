<template>
  <div class="share">
    <p>
      Hier ist das Ende der Seite erreicht. Wenn’s dir gefallen hat, teile es
      doch mit deinen Freunden.
    </p>
    <div class="share-buttons">
      <ShareNetwork
        network="WhatsApp"
        :url="currentURL"
        title="Lukas Luftläufer"
        description="Höre Musik und male dazu"
        hashtags="Impressionismus, Neoklassik"
      >
        <button>
          Mit WhatsApp teilen
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="#333448"
            stroke-width="1"
            style="margin-left: 10px"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
            />
          </svg>
        </button>
      </ShareNetwork>
      <ShareNetwork
        network="Telegram"
        :url="currentURL"
        title="Lukas Luftläufer"
        description="Höre Musik und male dazu"
        hashtags="Impressionismus, Neoklassik"
      >
        <button>
          Mit Telegram teilen
          <svg
            fill="none"
            stroke="#333448"
            stroke-width="1"
            style="margin-left: 10px; transform: rotate(45deg)"
            width="32"
            height="32"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
            />
          </svg>
        </button>
      </ShareNetwork>
    </div>
    <div class="footer__image">
      <img src="../assets/images/waves.svg" alt="" />
    </div>
    <footer>
      <a target="_blank" href="https://www.luftlaeufer.de">Impressum</a>
    </footer>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { ShareNetwork } from "vue-social-sharing";
const currentURL = computed(() => window.location.href);
</script>

<style lang="scss">
.share {
  border-top: 1px solid $color2;
  padding: 20px 0 0 0;
  margin-top: 150px;
}

.share-buttons {
  display: grid;
  gap: 20px;
}

.footer__image {
  position: relative;
  margin-top: 100px;
  transform: rotate(180deg);

  img {
    width: 100vw;
    margin-left: calc(1440px / 2 - 100vw / 2 - 28px);

    @include break(1440px) {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100vw;
    margin-left: calc(1440px / 2 - 100vw / 2 - 28px);
    height: 100%;
    background-image: linear-gradient(to bottom, transparent, $color1);

    @include break(1440px) {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }
}

footer {
  background: $color7;
  width: 100vw;
  margin-left: calc(1440px / 2 - 100vw / 2 - 28px);
  padding: 40px;
  margin-top: -1px;

  @include break(1440px) {
    width: calc(100% + 40px);
    margin-left: -20px;
  }

  a {
    color: white;
    float: right;

    &:hover {
      color: $color1;
    }
  }
}
</style>