<template>
  <div class="header">
    <div class="header__image">
      <img src="../assets/images/waves.svg" alt="" />
    </div>
    <h1>Lukas<br />Luftläufer</h1>
    <h2>The past inside the present</h2>
    <br />
    <p>
      Impressionismus? Kennste? Kennste? Find ich ja ganz gut. Musikalisch wie
      auch Malerisch. Aber daran kann man sich doch nach einem Jahrhundert nicht
      mehr erlaben.
      <a target="_blank" href="https://www.youtube.com/watch?v=tcNMl1Gra4o">
        Wo ist denn der latest shit?</a
      >
      Wo ist die Inspiration? Wo ist das alles? Ich hör’ hier nen bisschen
      Neoklassik. Aber das kann’s doch nicht sein!
    </p>
    <p>
      Warum also das Ganze nicht mal ins Jahr {{ year }} übersetzen? Ihr könnt
      hier meine Musik abspielen (Bonuspunkte, wer die Inspiration raushört) und
      auf der Leinwand weiter unten malen. Mit den Slidern könnt ihr die
      Funktionsweise der Pinsel verändern und am Ende euer Bild downloaden. Viel
      Spaß!
    </p>
  </div>
</template>

<script setup>
import { ref } from "vue";
const year = ref(new Date().getFullYear());
</script>

<style lang="scss" scoped>
.header__image {
  position: relative;
  margin-bottom: 80px;

  @include break(900px) {
    margin-bottom: 40px;
  }

  img {
    width: 100vw;
    margin-left: calc(1440px / 2 - 100vw / 2 - 28px);

    @include break(1440px) {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100vw;
    margin-left: calc(1440px / 2 - 100vw / 2 - 28px);
    height: 100%;
    background-image: linear-gradient(to bottom, transparent, $color1);

    @include break(1440px) {
      width: calc(100% + 40px);
      margin-left: -20px;
    }
  }
}

.header {
  margin-bottom: 150px;

  @include break(900px) {
    margin-bottom: 50px;
  }
}
</style>