<template>
  <div class="player">
    <div class="pianoImage">
      <img src="@/assets/images/piano.jpeg" alt="Ein Klavier" />
    </div>
    <div class="player__controls">
      <button @click="prev">
        <svg
          fill="none"
          viewBox="0 0 24 24"
          stroke="#333448"
          stroke-width="1"
          style="margin-right: 10px"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
          />
        </svg>
        PREV
      </button>
      <button class="greenHover" @click="play" v-if="!isPlaying">
        PLAY
        <svg fill="none" viewBox="0 0 24 24" stroke="#333448" stroke-width="1">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
      <button class="yellowHover" @click="play" v-else>
        <svg
          fill="none"
          viewBox="0 0 24 24"
          stroke="#333448"
          stroke-width="1"
          style="height: 32px"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
      <button @click="next">
        NEXT
        <svg fill="none" viewBox="0 0 24 24" stroke="#333448" stroke-width="1">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M13 5l7 7-7 7M5 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
    <av-waveform
      :audio-src="audioURLs[currentTrackID]"
      :canv-width="ww"
      :canv-height="wh"
      :playtime="false"
      :playedLineWidth="parseInt(1)"
      :noplayedLineWidth="parseFloat(0.3)"
      playtimeSliderColor="#d3958e"
      playedLineColor="#d3958e"
      noplayed-line-color="#547836"
      :key="currentTrackID"
      ref="audioPlayer"
      :audio-controls="false"
      id="audio"
    >
    </av-waveform>
    <button @click="tracklist = !tracklist" style="margin: 0 auto">
      Tracklist
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="#333448"
        stroke-width="1"
        style="margin-left: 10px"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4 6h16M4 10h16M4 14h16M4 18h16"
        />
      </svg>
    </button>
    <Tracklist
      v-if="tracklist"
      @indexFromPlaylist="trackSelectionFromPlayList"
      :currentlyPlaying="currentTrackID"
    />
  </div>
</template>


<script setup>
import Tracklist from "@/components/Tracklist.vue";

import { useStore } from "vuex";
import { onMounted, ref, watchEffect, watch, computed, onUpdated } from "vue";
const store = useStore();
const audioURLs = store.state.audio.map((track) => track.url);
const currentTrackID = ref(0);
const audioPlayer = ref(null);
const isPlaying = ref(false);
const ww = ref(window.innerWidth * 0.75);
const wh = ref(window.innerHeight * 0.2);

if (window.innerWidth <= 900) {
  ww.value = window.innerWidth;
}

const tracklist = ref(false);

const playerHeightCSS = computed(() => {
  return wh.value + "px";
});

onMounted(() => {
  //let audio = document.querySelector("#audio div audio");
  /*   audioPlayer.value.audio.onended = function () {
    console.log("ended");
    play();
    next();
    setTimeout(play, 1000);
  }; */
});

onUpdated(() => {
  audioPlayer.value.audio.onended = function () {
    //console.log("ended");
    currentTrackID.value++;
  };
});

watch(currentTrackID, () => {
  isPlaying.value = false;
  audioPlayer.value.audio.pause();
  setTimeout(play, 1000);
});

const next = () => {
  if (currentTrackID.value >= audioURLs.length - 1) {
    currentTrackID.value = 0;
  } else {
    currentTrackID.value++;
  }
};
const prev = () => {
  if (currentTrackID.value <= 0) {
    currentTrackID.value = audioURLs.length - 1;
  } else {
    currentTrackID.value--;
  }
};

const play = () => {
  isPlaying.value = !isPlaying.value;
  if (isPlaying.value) {
    audioPlayer.value.audio.play();
  } else {
    audioPlayer.value.audio.pause();
    isPlaying.value = false;
  }
};

const trackSelectionFromPlayList = (index) => {
  currentTrackID.value = index;
  //setTimeout(play, 1000);
};
</script>

<style lang="scss">
.player {
  display: flex;
  margin: 100px 0;
  flex-direction: column;

  @include break(900px) {
    margin: 50px 0;
  }

  &__controls {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    @include break(750px) {
      justify-content: space-between;
    }

    button {
      margin-right: 10px;
    }
  }

  /*   #audio {
    transform: rotate(90deg);
    transform-origin: left;
  } */
}

#audio canvas {
  display: block;
  max-width: 100%;
  margin: 0 auto 40px auto;
  background: transparent;
  // margin-bottom: 40px;
}

.pianoImage {
  img {
    width: 400px;
    max-width: 100%;
    height: auto;
    border-radius: 20px;
    margin: 20px auto;
    display: block;
    border: 10px solid $color7;
    box-shadow: 0 0 42px rgba(51, 52, 72, 0.08);
  }
}
</style>